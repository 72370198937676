<template>
    <div id="app">
    <!-- <v-card
        class="ma-0 pa-0"
        color="#ffffff"
        dark>
        <v-card-title>
            <v-row justify="center">
                <v-spacer></v-spacer>
                <v-img
                    :src="require('@/assets/logo3.png')"
                    contain
                    height="40"
                    max-width="300"
                />
                <v-spacer></v-spacer>
                <v-btn class="mt-1 mr-1" icon @click="onClickLogOff" color="#0D47A1" height="30" width="30"><v-icon>mdi-export</v-icon></v-btn>
            </v-row>
        </v-card-title>

        <v-card-actions>
            <v-list-item class="title_bg ma-n2">
                <v-list-item-avatar color="grey darken-3">
                    <v-img
                        class="elevation-6"
                        alt=""
                        src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                    ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{ userInfo.tm_firstname }}</v-list-item-title>
                    <v-list-item-title>{{ userInfo.tm_lastname }}</v-list-item-title>
                </v-list-item-content>

                <div>
                    <v-row
                        align="center"
                        justify="end">
                        <div class="mt-2 mr-2">
                            <div class="form-control-static" @click="isPopupQrcode = true">
                                <qrcode-vue
                                class="qrcode"
                                :value="userInfo.tm_code"
                                :margin='margin >>> 0'
                                :renderAs="renderAs"
                                :size="size >>> 0"
                                :level="level"
                                :background="background"
                                :foreground='foreground'
                                />
                            </div>
                        </div>
                        <div class="mr-2">{{ $t('home.membercode') }}<br/>{{ userInfo.tm_code }}</div>
                        <span class="mr-0">|</span>
                        <div style="padding: 10px" v-if="this.$i18n.locale=='en'">
                            <v-img 
                                contain
                                :src="require('@/assets/th.png')"
                                height="30"
                                width="30"
                                @click="langThaiClick"
                            />
                        </div>
                        <div style="padding: 10px" v-else>
                            <v-img 
                                contain
                                :src="require('@/assets/en.png')"
                                height="30"
                                width="30"
                                @click="langEngClick"
                            />
                        </div>
                    </v-row>
                </div>
            </v-list-item>
        </v-card-actions>
  </v-card> -->

    <v-card
        class="ma-0 pa-0"
        color="#ffffff"
        dark>
        
        <v-app-bar
        app
        :fixed="true"
        color="#ffffff"
        rounded="0"
        dark
        dense>
            <v-row justify="center">
                <v-spacer></v-spacer>
                <v-img
                    :src="require('@/assets/logo3.png')"
                    contain
                    height="40"
                    max-width="300"
                />
                <v-spacer></v-spacer>
                <v-btn class="mt-1 mr-1" icon @click="onClickLogOff" color="#0D47A1" height="30" width="30"><v-icon>mdi-export</v-icon></v-btn>
            </v-row>
        </v-app-bar>

        <!-- <v-card-title>
            <v-row justify="center">
                <v-spacer></v-spacer>
                <v-img
                    :src="require('@/assets/logo3.png')"
                    contain
                    height="40"
                    max-width="300"
                />
                <v-spacer></v-spacer>
                <v-btn class="mt-1 mr-1" icon @click="onClickLogOff" color="#0D47A1" height="30" width="30"><v-icon>mdi-export</v-icon></v-btn>
            </v-row>
        </v-card-title> -->

        <v-card-actions>
            <v-list-item class="title_bg ma-n2" dense>
                <v-list-item-avatar color="grey darken-3">
                    <v-img
                        class="elevation-6"
                        alt=""
                        src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                    ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{ userInfo?.tm_firstname ?? "" }}</v-list-item-title>
                    <v-list-item-title>{{ userInfo?.tm_lastname ?? "" }}</v-list-item-title>
                </v-list-item-content>

                <div>
                    <v-row
                        align="center"
                        justify="end">
                        <div class="mt-2 mr-2">
                            <div class="lang_cursor form-control-static" @click="isPopupQrcode = true">
                                <qrcode-vue
                                class="qrcode"
                                :value="'WEBA' + userInfo?.tm_code?.trim()"
                                :margin='margin >>> 0'
                                :renderAs="renderAs"
                                :size="size >>> 0"
                                :level="level"
                                :background="background"
                                :foreground='foreground'
                                />
                            </div>
                        </div>
                        <div class="mr-2">{{ $t('home.membercode') }}<br/>{{ userInfo?.tm_code ?? "" }}</div>
                        <span class="mr-0">|</span>
                        <div class="lang_cursor" style="padding: 10px" v-if="this.$i18n.locale=='en'">
                            <v-img 
                                contain
                                :src="require('@/assets/th.png')"
                                height="30"
                                width="30"
                                @click="langThaiClick"
                            />
                        </div>
                        <div class="lang_cursor" style="padding: 10px" v-else>
                            <v-img 
                                contain
                                :src="require('@/assets/en.png')"
                                height="30"
                                width="30"
                                @click="langEngClick"
                            />
                        </div>
                    </v-row>
                </div>
            </v-list-item>
        </v-card-actions>
  </v-card>

<template>
    <div class="text-center">
        <v-dialog
        v-model="isPopupQrcode"
        width="400"
        >
        <v-card>
            <v-card-title class="text-h5 primary white--text" dark>QRCODE - {{ userInfo?.tm_code ?? "" }}</v-card-title>

            <v-card-text class="pa-5 text-center font-weight-bold">
                <div class="mt-2 mr-2 mb-3">
                    <div class="form-control-static" @click="isPopupQrcode = true">
                        <qrcode-vue
                        class="qrcode"
                        :value="'WEBA' + userInfo?.tm_code?.trim()"
                        :margin='margin >>> 0'
                        :renderAs="renderAs"
                        :size="200 >>> 0"
                        :level="level"
                        :background="background"
                        :foreground='foreground'
                        />
                    </div>
                </div>
                <label class="label_qrcode">{{ userInfo?.tm_code ?? "" }}</label>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark @click="isPopupQrcode = false" width="100">{{ $t('home.popup_qr_close_button') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>
</template>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  name: "Header",
  data() {
    return {
        // QRCODE
        // value: 'https://example.com',
        size: 40,
        level: 'H',
        background: '#ffffff',
        foreground: '#000000',
        renderAs: 'svg',
        margin: 0,

        isPopupQrcode: false,

        // userInfo: {
        //     "tm_firstname": "Somkid",
        //     "tm_lastname": "Wongkasikorn",
        //     "tm_name": "Micky-5 Updateinfo-5",
        //     "tm_email": "Mickytest4@villamarket.com",
        //     "tm_code": "YB982",
        //     "tm_refcode": "IW9982",
        //     "Mobile1": "0959479251",
        //     "mp_point": 0,
        //     "mp_stamp": 0
        // }
    };
  },
//   async mounted() {
//     console.log('-- userInfo --', this.userInfo);
//   },
  methods: {
    onClickLogOff() {
      this.$store.dispatch("doLogout");
    },
    langThaiClick() {
      this.$i18n.locale = 'th';
      localStorage.setItem('lang', 'th');
    },
    langEngClick() {
      this.$i18n.locale = 'en';
      localStorage.setItem('lang', 'en');
    }
  },
  components: {
    QrcodeVue
  },
  computed: {
    userInfo() {
        return JSON.parse(localStorage.getItem('userInfo') ?? {});
    }
  }
}

</script>

<style scoped>
.title_bg {
    /* background-color: #303F9F; */
    color: #ffffff;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(48,63,159,1) 50%, rgba(0,212,255,1) 100%);
    font-size: 0.9rem !important;
}

.label_qrcode {
  font-size: 30px;
  font-weight: bold;
  color: #0D47A1;
}
.lang_cursor {
  cursor: pointer;
}
</style>