<template>
  <!-- <v-container fluid> -->
    <v-bottom-navigation
        app
        :value="selectIndex"
        class="bottom_bg"
        grow
        dark>
        <v-btn value="0" to="/rewards">
        <span>{{ $t('home.bottom_nav_0') }}</span>
        <v-icon>mdi-gift-open-outline</v-icon>
        </v-btn>

        <v-btn value="1" to="/home">
        <span>{{ $t('home.bottom_nav_1') }}</span>
        <v-icon>house</v-icon>
        </v-btn>

        <v-btn value="2" to="/history">
        <span>{{ $t('home.bottom_nav_2') }}</span>
        <v-icon>mdi-information-outline</v-icon>
        </v-btn>
    </v-bottom-navigation>
  <!-- </v-container> -->
</template>

<script>

export default {
  name: "Bottom",
  data() {
    return {
      selectIndex: 1
    };
  },
  // methods: {
  // }
}

</script>

<style scoped>
.bottom_bg {
    /* background-color: #303F9F; */
    color: #FFD600;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(48,63,159,1) 50%, rgba(0,212,255,1) 100%);
    font-size: 0.9rem !important;
}
</style>